<template>
  <div v-if="show" class="modal-detail">
    <vxe-modal v-model="show" :title="name" width="500" min-width="500" height="400" min-height="400" showFooter
      esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="auto" label-position="top" :rules="rules">
          <el-form-item label="车牌号">
            {{data.vehicleNumber}}
          </el-form-item>
          <el-form-item prop="primaryWeight" label="原发/预装">
            <el-input v-model="data.primaryWeight" placeholder="输入原发/预装" type="number"></el-input>
          </el-form-item>
          <el-form-item prop="grossWeight" label="毛重">
            <el-input v-model="data.grossWeight" placeholder="输入毛重" type="number"></el-input>
          </el-form-item>
          <el-form-item prop="tareWeight" label="皮重">
            <el-input v-model="data.tareWeight" placeholder="输入皮重" type="number"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      name: '',
      data: {},
      rules: {
        primaryWeight: [{ required: true, trigger: 'blur', message: '输入原发/预装' }],
        grossWeight: [{ required: true, trigger: 'blur', message: '输入毛重' }],
        tareWeight: [{ required: true, trigger: 'blur', message: '输入皮重' }]
      },
      loading: false
    }
  },
  methods: {
    /** 打开界面方法 */
    display(data) {
      this.show = true
      this.data = data.data
      this.name = data.name
      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.clearValidate()
      })
    },
    /** 保存方法 */
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = this.data
          this.$axios
            .post('/order/bound/update', params)
            .then(res => {
              if (res) {
                this.$message.success('修改成功')
                this.show = false
                this.$emit('init')
              }
              this.loading = false
            })
            .catch(err => {
              console.log('修改失败', err)
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-detail {
  ::v-deep .vxe-modal--content {
    white-space: normal !important;
  }
}
</style>