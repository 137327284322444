<template>
  <div class="base-container">
    <el-row :gutter="10">
      <el-col :span="12">
        <vxe-toolbar perfect size="mini">
          <template v-slot:buttons>
            <div class="cf table-header-padding">
              <div class="fl">
                <div class="fl">
                  <p style="margin: 0 10px 0 0; font-size: 22px">过磅单</p>
                </div>
                <el-select v-model="state" filterable clearable placeholder="选择过磅状态" size="mini"
                  style="margin-right: 10px; width: 120px" @change="init(); page.currentPage = 1">
                  <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
                <el-input placeholder="模糊搜索车号" size="mini" v-model="carCode" style="width: 120px; margin-right: 10px"
                  @change="init(); page.currentPage = 1" clearable>
                </el-input>
              </div>
              <div class="fr">
                <el-tooltip class="item" effect="dark" content="查询" placement="top">
                  <el-button size="mini" @click="searchInit()" circle class="el-icon-search">
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="清空" placement="top">
                  <el-button size="mini" @click="carCode=''; state=''; searchInit()" circle class="el-icon-delete">
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="导出" placement="top">
                  <el-button @click="exportDataEvent()" size="mini" class="el-icon-download" circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="打印" placement="top">
                  <el-button @click="printDataEvent()" size="mini" circle class="el-icon-printer">
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </template>
        </vxe-toolbar>
        <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}"
          :expand-config="{accordion: true}" highlight-hover-row auto-resize size="small" ref="bangDan" resizable
          row-id="id" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
          show-header-overflow show-overflow @checkbox-change="changeBoxChange" @checkbox-all="changeBoxChange">
          <template #empty>
            <el-empty></el-empty>
          </template>
          <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
          <vxe-table-column field="carNum" title="车牌号" width="115"></vxe-table-column>
          <vxe-table-column field="netWeight" title="净重" width="75">
            <template v-slot="scope">
              {{ $utils.commafy(Number(scope.row.netWeight), { digits: 2, round: false }) }}
            </template>
          </vxe-table-column>
          <vxe-table-column field="assignTime" title="派车时间" min-width="200"></vxe-table-column>
          <vxe-table-column field="state" title="状态" width="80"></vxe-table-column>
          <vxe-table-column fixed="right" align="center" width="240" title="操作">
            <template slot-scope="scope">
                <el-button type="text" size="mini" @click="getCheck(scope.row)">查看</el-button>
                <el-button type="text" size="mini" @click="updateWeight(scope.row)" v-if="!['拒绝'].includes(scope.row.state)">修改</el-button>
                <el-button type="text" size="mini" @click="print(scope.row)" v-if="['二次过磅', '出厂'].includes(scope.row.state)">补打</el-button>
                <el-button size="mini" type="text" @click="getBack(scope.row)" v-if="!['未进厂', '未审核', '拒绝'].includes(scope.row.state)">回退</el-button>
            </template>
          </vxe-table-column>
        </vxe-table>
        <vxe-pager perfect size="mini" align="right" :loading="loading" :current-page="page.currentPage"
          :page-size="page.pageSize" :total="page.totalResult" class="ext-pager" selectionChange
          :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChange">
          <template v-slot:left>
            <el-button @click="merge()" size="mini" type="primary">合并数据</el-button>
          </template>
        </vxe-pager>
      </el-col>
      <el-col :span="12">
        <vxe-toolbar perfect size="mini">
          <template v-slot:buttons>
            <div class="cf table-header-padding">
              <div class="fl">
                <div class="fl">
                  <p style="margin: 0 10px 0 0; font-size: 22px">过磅记录</p>
                </div>
                <el-button size="mini" type="primary" @click="createRecord" style="margin-right: 10px">新增</el-button>
                <el-input placeholder="模糊搜索车号" size="mini" v-model="newCarCode" style="width: 120px; margin-right: 10px"
                          @change="searchRecordInit(); page.currentPage = 1" clearable>
                </el-input>
              </div>
              <div class="fr">
                <el-tooltip class="item" effect="dark" content="查询" placement="top">
                  <el-button size="mini" @click="searchRecordInit()" circle class="el-icon-search">
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="导出" placement="top">
                  <el-button @click="exportDataEventRecord()" size="mini" class="el-icon-download" circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="打印" placement="top">
                  <el-button @click="printDataEventRecord()" size="mini" circle class="el-icon-printer">
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </template>
        </vxe-toolbar>
        <vxe-table :align="allAlign" :data="dataRecord" :height="recordHeight" border :export-config="{}"
          :print-config="{}" highlight-hover-row auto-resize size="small" ref="record" resizable row-id="id"
          v-loading="recordLoading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
          @checkbox-change="changeBoxRecordChange" @checkbox-all="changeBoxRecordChange">
          <template #empty>
            <el-empty></el-empty>
          </template>
          <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
          <vxe-table-column field="vehicleNumber" title="车牌号" width="90"></vxe-table-column>
          <vxe-table-column field="weight" title="重量" width="60"></vxe-table-column>
          <vxe-table-column field="createTime" title="过磅时间" width="180"></vxe-table-column>
          <vxe-table-column field="weighAddress" title="过磅地址" width="90"></vxe-table-column>
          <vxe-table-column field="photo" title="过磅图片" min-width="715">
            <template slot-scope="scope">
              <div v-if="(scope.row.urlList && scope.row.urlList.length == 0) || !scope.row.urlList">
                <img :src="noImg" class="pic" width="160" height="90" />
              </div>
              <div v-else>
                <viewer :images="scope.row.urlList">
                  <img v-for="src in scope.row.urlList" :src="src" :key="src" width="160" height="90" />
                </viewer>
              </div>
            </template>
          </vxe-table-column>
          <vxe-table-column field="inout" title="进出厂" width="60">
            <template v-slot="{row}">
              <el-tag :type="row.inout === 'in' ? 'primary' : 'success'" size="small">
                {{ row.inout === 'in' ? '进厂' : '出厂' }}</el-tag>
            </template>
          </vxe-table-column>
          <vxe-table-column field="deviceName" title="设备名称" width="80"></vxe-table-column>
        </vxe-table>
        <vxe-pager perfect size="mini" align="right" :loading="recordLoading" :current-page="pageRecord.currentPage"
          :page-size="pageRecord.pageSize" :total="pageRecord.totalResult" class="ext-pager" selectionChange
          :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChangeRecord">
          <template v-slot:left>
            <el-button @click="del()" size="mini" type="danger">批量删除</el-button>
          </template>
        </vxe-pager>
      </el-col>
    </el-row>
    <update-weight ref="updateWeight" @init="init" />
    <create-record ref="createRecord" @init="recordInit" />
    <check ref="check" />
  </div>
</template>

<script>
import UpdateWeight from './components/updateWeight'
import check from './components/check'
import createRecord from "./components/createRecord";
export default {
  name: 'bangDan',
  data() {
    return {
      data: [],
      dataRecord: [],
      state: '',
      carCode: '',
      newCarCode: '',
      allAlign: 'center',
      loading: false,
      recordLoading: false,
      detailLoading: false,
      checkBox: [],
      checkLength: 0,
      checkRecordBox: [],
      checkRecordLength: 0,
      stateList: [
        { value: 'NoPass', label: '未审核' },
        { value:'Refuse', label:'拒绝' },
        { value: 'NoEnter', label: '未进厂' },
        { value: 'Enter', label: '已进厂' },
        { value: 'Confirm', label: '确认装卸货' },
        { value: 'First', label: '一次过磅' },
        { value: 'Second', label: '二次过磅' },
        { value: 'Out', label: '出厂' },
      ],
      tableHeight: window.innerHeight - 190 + 'px',
      recordHeight: window.innerHeight - 190 + 'px',
      page: {
        pageSize: 20,
        currentPage: 1,
        totalResult: 0,
      },
      pageRecord: {
        pageSize: 20,
        currentPage: 1,
        totalResult: 0,
      },
      noImg: require('@/assets/no-img.jpg'),
    }
  },
  components: {
    UpdateWeight,
    check,
    createRecord
  },
  created() {
    const _this = this
    _this.init()
    _this.recordInit()
  },
  mounted() {
    const _this = this
    window.onresize = () => {
      if (_this.$refs.bangDan) {
        _this.tableHeight = window.innerHeight - _this.$refs.bangDan.$el.offsetTop - 135 + 'px'
      }
      if (_this.$refs.record) {
        _this.recordHeight = window.innerHeight - _this.$refs.record.$el.offsetTop - 135 + 'px'
      }
    }
  },
  methods: {
    // 磅单列表
    init() {
      const _this = this
      _this.$set(_this, 'loading', true)
      const params = {
        params: {
          pageNum: this.page.currentPage,
          pageSize: this.page.pageSize,
        },
      }
      if (!['', null, undefined].includes(this.carCode)) params.params.carCode = this.carCode
      if (!['', null, undefined].includes(this.state)) params.params.state = this.state
      _this.$axios
        .get('/order/bound/page', params)
        .then((res) => {
          if (res) {
            const data = res.data.data
            if (!['', null, undefined].includes(data.list)) {
              this.data = data.list
              this.page.currentPage = data.pageNum
              this.page.totalResult = data.total
              this.checkLength = 0
              this.checkBox = []
            }
          }
          this.loading = false
        })
        .catch((err) => {
          console.log('bangDan Err:', err)
          _this.$set(_this, 'loading', false)
        })
    },
    recordInit() {
      const _this = this
      _this.$set(_this, 'recordLoading', true)
      const params = {
        params: {
          pageNum: this.pageRecord.currentPage,
          pageSize: this.pageRecord.pageSize,
        },
      }
      if (!['', null, undefined].includes(this.newCarCode)) params.params.carCode = this.newCarCode
      _this.$axios
        .get('/order/bound/weighRecord/list', params)
        .then((res) => {
          if (res) {
            const data = res.data.data
            if (!['', null, undefined].includes(data.list)) {
              this.dataRecord = data.list
              this.dataRecord.forEach((item) => {
                item.urlList = []
                if (item.photoListUrl1 != '') item.urlList.push(item.photoListUrl1)
                if (item.photoListUrl2 != '') item.urlList.push(item.photoListUrl2)
                if (item.photoListUrl3 != '') item.urlList.push(item.photoListUrl3)
                if (item.photoListUrl4 != '') item.urlList.push(item.photoListUrl4)
              })
              this.pageRecord.currentPage = data.pageNum
              this.pageRecord.totalResult = data.total
              this.checkRecordLength = 0
              this.checkRecordBox = []
            }
          }
          this.recordLoading = false
        })
        .catch((err) => {
          console.log('bangDan Err:', err)
          _this.$set(_this, 'recordLoading', false)
        })
    },
    handlePageChange({ currentPage, pageSize }) {
      const _this = this
      _this.page.currentPage = currentPage
      _this.page.pageSize = pageSize
      _this.init()
    },
    handlePageChangeRecord({ currentPage, pageSize }) {
      const _this = this
      _this.pageRecord.currentPage = currentPage
      _this.pageRecord.pageSize = pageSize
      _this.recordInit()
    },
    // 导出列表
    exportDataEvent() {
      const _this = this
      _this.$refs.bangDan.openExport()
    },
    // 打印列表
    printDataEvent() {
      const _this = this
      _this.$refs.bangDan.openPrint()
    },
    // 导出列表
    exportDataEventRecord() {
      const _this = this
      _this.$refs.record.openExport()
    },
    // 打印列表
    printDataEventRecord() {
      const _this = this
      _this.$refs.record.openPrint()
    },
    merge() {
      if (this.checkLength == 1 && this.checkRecordLength == 1) {
        const params = {
          boundId: this.checkBox[0].id,
          weighRecordId: this.checkRecordBox[0].id,
        }
        this.$axios.post('/order/bound/merge', params).then((res) => {
          if (res) {
            this.$message.success('合并成功')
            this.init()
            this.recordInit()
          }
        })
      } else {
        this.$message.warning('请保证左侧、右侧列表各一条数据')
      }
    },
    del() {
      const ids = this.checkRecordBox.map((item) => item.id)
      this.recordLoading = true
      ids.forEach((id, index) => {
        this.$axios.get(`/order/bound/weighRecord/del/${id}`).then((res) => {
          if (res) {
            if (index + 1 == ids.length) {
              this.$message.success('批量删除成功')
              this.recordInit()
              this.recordLoading = false
            }
          } else {
            this.recordLoading = false
          }
        })
      })
    },
    changeBoxChange() {
      this.checkBox = this.$refs.bangDan.getCheckboxRecords()
      this.checkLength = this.$refs.bangDan.getCheckboxRecords().length
    },
    changeBoxRecordChange() {
      this.checkRecordBox = this.$refs.record.getCheckboxRecords()
      this.checkRecordLength = this.$refs.record.getCheckboxRecords().length
    },
    getCheck(row) {
      this.$axios.get(`/order/bound/detail/${row.id}`).then((res) => {
        if (res) {
          const data = { data: res.data.data, name: res.data.data.carNum }
          this.$refs.check.display(data)
        }
      })
    },
    print(row) {
      this.$axios.get(`/order/bound/print/${row.id}`).then((res) => {
        if (res) this.$message.success('补打成功')
      })
    },
    updateWeight(row) {
      this.$axios.get(`/order/bound/detail/${row.id}`).then((res) => {
        if (res) {
          const params = res.data.data
          const data = {
            data: {
              id: row.id,
              primaryWeight: params.primaryWeight,
              grossWeight: params.grossWeight,
              tareWeight: params.tareWeight,
              vehicleNumber: params.vehicleNumber
            },
            name: '修改毛皮净'
          }
          this.$refs.updateWeight.display(data)
        }
      })
    },
    searchInit() {
      this.page.currentPage = 1
      this.init()
    },
    searchRecordInit() {
      this.pageRecord.currentPage = 1
      this.recordInit()
    },
    createRecord() {
      const data = {data: {}, name: '新增过磅记录'}
      this.$refs.createRecord.display(data)
    },
    getBack(row) {
      this.$confirm(`确定回退该条数据吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            this.$axios.get(`/order/bound/back/${row.id}`).then((res) => {
              if (res) {
                this.init()
                this.$message.success(`回退成功`)
              }
            })
          })
          .catch(() => {})
    }
  },
}
</script>

<style lang="sass" scoped>
</style>
