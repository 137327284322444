<template>
  <div v-if="show" class="modal-detail">
    <vxe-modal v-model="show" :title="name" width="500" min-width="500" height="350" min-height="350" showFooter
               esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="auto" label-position="right" :rules="rules">
          <el-form-item prop="vehicleNumber" label="车牌号">
            <el-input v-model="data.vehicleNumber" placeholder="输入车牌号" type="text" name="vehicleNumber" auto-complete="no"></el-input>
          </el-form-item>
          <el-form-item prop="inout" label="进出厂">
            <el-select filterable default-first-option v-model="data.inout" placeholder="选择进出厂"
                       style="width: 100%">
              <el-option :label="item.label" :value="item.value" v-for="(item, key) in inoutType" :key="key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="weighAddress" label="过磅地址">
            <el-input v-model="data.weighAddress" placeholder="输入过磅地址" type="text" name="weighAddress" auto-complete="no"></el-input>
          </el-form-item>
          <el-form-item prop="weight" label="重量">
            <el-input v-model="data.weight" placeholder="输入重量" type="number"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    const validateCarCode = (rule, value, callback) => {
      if (value) {
        if (this.$validate.validateCarNum(value)) {
          callback()
          this.isCode = true
        } else {
          callback(new Error('请输入正确的车牌号'))
          this.isCode = false
        }
      } else {
        callback(new Error('请输入车牌号'))
        this.isCode = false
      }
    }
    return {
      show: false,
      name: '',
      data: {},
      rules: {
        vehicleNumber: [{ required: true, trigger: 'change', validator: validateCarCode }],
        inout: [{ required: true, trigger: 'change', message: '选择进出厂' }],
        weighAddress: [{ required: true, trigger: 'blur', message: '输入过磅地址' }],
        weight: [{ required: true, trigger: 'blur', message: '输入重量' }]
      },
      loading: false,
      inoutType: [
        {value: 'in', label: '进厂'},
        {value: 'out', label: '出厂'}
      ]
    }
  },
  methods: {
    /** 打开界面方法 */
    display(data) {
      this.show = true
      this.data = data.data
      this.name = data.name
      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.clearValidate()
      })
    },
    /** 保存方法 */
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.data['tenantId'] = localStorage.getItem('tenantId')
          const params = this.data
          this.$axios
              .post('/order/bound/weighRecord/create', params)
              .then(res => {
                if (res) {
                  this.$message.success('新增成功')
                  this.$emit('init')
                  this.show = false
                }
                this.loading = false
              })
              .catch(err => {
                console.log('新增失败', err)
                this.loading = false
              })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-detail {
  ::v-deep .vxe-modal--content {
    white-space: normal !important;
  }
}
</style>